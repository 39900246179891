$(document).on('turbolinks:load', function(){
  $(document).off('change', '.js-ceo-section-form');
  $(document).on('change', '.js-ceo-section-form', function(e){
    checkCeoForm(this);
  });

  $(document).off('keyup', '.js-ceo-section-form');
  $(document).on('keyup', '.js-ceo-section-form', function(e){
    checkCeoForm(this, false);
  });

  var checkCeoForm = function(form, save = true) {
    var complete = true;
    $(form).find('.ceo-participate__question').each(function(i, el) {
      if( $(el).hasClass('js-comments-row') ) return true;

      if( $(el).hasClass('js-text-response-row') && $(el).find('textarea[data-required=true]').val() === '') {
        complete = false;

        return false;
      } else if( !$(el).hasClass('js-text-response-row') && $(el).find('[type=radio]:checked').length == 0) {
        complete = false;

        return false;
      } else if( $(el).find('[type=radio]:checked').length > 0 && $(el).find('[type=radio]:checked').val() < 3 && $(el).find('[type=radio]:checked').val() > 0) {
        complete = $(form).find('.ceo-participate__comments-field').val() != '';
        $(form).find('.ceo-participate__comments-label').each(function(i, el) { $(el).css('top', '-13px'); });
        $(form).find('.ceo-participate__comments-note').each(function(i, el) { $(el).removeClass('d-none'); });
      }
    });

    if(complete) {
      $(form).find('.js-ceo-next-btn').prop('disabled', false);
      $(form).find('.ceo-participate__comments-label').each(function(i, el) { $(el).css('top', '0'); });
      $(form).find('.ceo-participate__comments-note').each(function(i, el) { $(el).addClass('d-none'); });
    } else {
      $(form).find('.js-ceo-next-btn').prop('disabled', true);
    }

    if(save) Rails.fire(form, 'submit');
  }

  if($('#ceo-competency-modal').length > 0 && $('#ceo-competency-modal').data('autodisplay')) {
    var competencyModal = new bootstrap.Modal(document.getElementById('ceo-competency-modal'));
    competencyModal.show();
  }

  if($('#ceo-qualities-modal').length > 0 && $('#ceo-qualities-modal').data('autodisplay')) {
    var qualitiesModal = new bootstrap.Modal(document.getElementById('ceo-qualities-modal'));
    qualitiesModal.show();
  }
});
