$(document).on('turbolinks:load', function(){
  var parsleyForm = $('form.js-board-form').parsley({
    errorClass: 'parsley-error',
    successClass: 'parsley-success',
    errorsWrapper: '<div class="invalid-feedback d-block"></div>',
    errorTemplate: '<span></span>'
  });

  var checkRequired = function() {
    if($('#board_no_managers').prop('checked')) {
      $('#board-am-fields .js-am-field').prop('required', false);
    } else if($('#board-am-fields .js-account-manager:not(.d-none)').length == 0)  {
      $('#board_no_managers').prop('required', true);
      parsleyForm.whenValidate({ group: 'noam' });
    } else {
      $('#board-am-fields .js-account-manager:not(.d-none) .js-am-field').prop('required', true);
      $('#board_no_managers').prop('required', false);
      parsleyForm.whenValidate({ group: 'noam' });
    }
  }
  
  $(document).off('click', '.js-board-new-am');  
  $(document).on('click', '.js-board-new-am', function(e) {
    var amFields = $('#new-am-fields')[0].innerHTML;
    $('#board-am-fields').append( amFields.replace( /__AM__INDEX__/g, Date.now().toString() ) );
    $('#board_no_managers').prop('disabled', true);
    checkRequired();
  });

  $(document).off('click', '.js-board-remove-am');  
  $(document).on('click', '.js-board-remove-am', function(e) {
    e.preventDefault();

    var am = $(this).data('am');
    $('#am-fields-' + am + ' .js-board-am-destroy-field').val('1');
    $('#am-fields-' + am + ' .js-am-field').prop('required', false);
    $('#am-fields-' + am).addClass('d-none');

    if($('#board-am-fields .js-account-manager:not(.d-none)').length == 0) $('#board_no_managers').prop('disabled', false);

    checkRequired();

    return false;
  });

  $(document).off('change', '.js-am-field');  
  $(document).on('change', '.js-am-field', function(e) {
    if($(this).val() != '') $('#board_no_managers').prop('checked', false);
    checkRequired();
  });

  $(document).off('change', '#board_no_managers');  
  $(document).on('change', '#board_no_managers', checkRequired);
});
