$(document).on('turbolinks:load', function(){
  var checkEnableButton = function() {
    if($('.js-board-filter:visible input:checked').length > 0) {
      $('#enable-submit').prop('disabled', false);
    } else {
      $('#enable-submit').prop('disabled', true);
    }
  }

  $(document).off('change', '#check_all_boards');
  $(document).on('change', '#check_all_boards', function(e){
    if( $(this).prop('checked') ) {
      $('.js-board-filter input').prop('checked', true);
      $('.js-board-filter.d-none input').prop('checked', false);
    } else {
      $('.js-board-filter input').prop('checked', false);
    }
    checkEnableButton();
  });

  $(document).off('change', '.js-board-filter input');
  $(document).on('change', '.js-board-filter input', function(e){
    if( !$(this).prop('checked') ) {
      $('#check_all_boards').prop('checked', false);
    }
    checkEnableButton();
  });

  $(document).off('keyup', '#filter-boards');
  $(document).on('keyup', '#filter-boards', function(e){
    var searchVal = $(this).val();
    if( searchVal != undefined ) {
      $('.js-board-filter').each(function(i, board) {
        if($(board).data('name').includes(searchVal.toLowerCase())) {
          $(board).removeClass('d-none');
        } else {
          $(board).addClass('d-none');
          $(board).find('input').prop('checked', false);
        }
      });

      $('.list-group .js-board-filter:visible:even').css('cssText', 'background-color: rgba(246, 246, 246, 0.5) !important');
      $('.list-group .js-board-filter:visible:odd').css('cssText', 'background-color: #ffffffff !important');
      checkEnableButton();
    }
  });
});
