window.removeSortableQuestions = function() {
  $('.js-sortable-questions').each(function(i, el) {
    if($(el).sortable('instance') !== undefined ) $(el).sortable('destroy');
  });
};

window.initSortableQuestions = function() {
  window.removeSortableQuestions();
  $('.js-sortable-questions').each(function(i, el) {
    $(el).sortable({
      handle: '.js-sortable-handle',
      update: function(event, ui) {
        var updateUrl = $(event.target).data('url');
        var attr = $(event.target).data('attr');
        var params = {
          authenticity_token: $('[name="csrf-token"]')[0].content,
          section: {}
        }

        params['section'][attr] = {};

        $(event.target).find('.js-sort-question').each(function(i, el) {
          var questionParams = {
            id: $(el).data('question'),
            order: i + 1
          }
          params['section'][attr][i] = questionParams;
        });

        $.ajax({
          method: 'PUT',
          url: updateUrl,
          dataType: 'json',
          contentType: 'application/json',
          data: JSON.stringify(params)
        });
      }
    });
  });
};

$(document).on('turbolinks:load', function(){
  window.initSortableQuestions();
  var updateQuestionOrder = function(section) {
    $('#editing-section-' + section + ' .js-question-fields:not(.d-none)').each(function(i, el) {
      $(el).find('.js-hidden-order').val(i + 1);
      $(el).find('.js-label-order').text(i + 1);
      $(el).find('.js-title-field').prop('placeholder', 'Goal ' + (i + 1) + ' Title');
    });
  };

  $('.js-sortable-sections').each(function(i, el) {
    $(el).sortable({
      handle: '.js-sortable-handle',
      update: function(event, ui) {
      }
    });
  });

  $(document).off('click', '.js-disabled');
  $(document).on('click', '.js-disabled', function(e) {
    e.preventDefault();
    return false;
  });

  $(document).off('click', '.js-cancel-new-section')
  $(document).on('click', '.js-cancel-new-section', function(e) {
    e.preventDefault();

    $('.js-editing-section-new').remove();
    $('.js-disabled').removeClass('js-disabled').removeClass('disabled');

    return false;
  });

  $(document).off('click', '.js-remove-question');
  $(document).on('click', '.js-remove-question', function(e) {
    e.preventDefault();

    var section = $(this).data('section');
    var question = $(this).data('question');

    $('#question-' + question).addClass('d-none');
    $('#question-' + question + ' .js-hidden-destroy').val('true');
    $('#question-' + question + ' input').removeAttr('required');
    $('#question-' + question + ' textarea').removeAttr('required');

    window.initParsleyForm('#editing-section-' + section + ' form');

    updateQuestionOrder(section);

    return false;
  });

  $(document).off('click', '.js-add-question');
  $(document).on('click', '.js-add-question', function(e) {
    e.preventDefault();

    var section = $(this).data('section');
    var template = $(this).data('template');

    var newId = Date.now().toString();
    var questionHtml = $(template).html().toString().replace(/__NEW_RECORD__/g, newId);

    $('#editing-section-' + section + ' #section-questions').append(questionHtml);

    updateQuestionOrder(section);

    if( $(this).data('mce') == '1' ) {
      TinyMCERails.initialize('default', {
        selector: '#question-' + newId + ' textarea',
        toolbar: 'bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | styleselect | bullist numlist link | undo redo',
        plugins: ['lists', 'paste', 'autoresize', 'link'],
        skin: 'gsw',
        menubar: false,
        pasteAsText: true,
        setup: function (editor) {
          editor.on('change', function () {
            tinymce.triggerSave();
          });

          editor.on('mouseenter', function() {
            $(editor.editorContainer).addClass('hover');
          });

          editor.on('mouseleave', function() {
            $(editor.editorContainer).removeClass('hover');
          });

          editor.on('focus', function() {
            $(editor.editorContainer).addClass('in-focus');
          });

          editor.on('blur', function() {
            $(editor.editorContainer).removeClass('in-focus');
          });
        }
      });
    }

    return false;
  });
});
