$(document).on('turbolinks:load', function(){
  $('#show-sidebar-roles').on('click', function(e) {
    e.preventDefault();
    if(!$('#sidebar-roles').hasClass('slideinleft') && !$('#sidebar-roles').hasClass('slideoutright')) {
      $('#sidebar-roles').removeClass('d-none');
      $('.sidebar-info').addClass('d-none');
      $('#sidebar-wrapper').addClass('no-overflow');

      $('#sidebar-roles').one("animationend webkitAnimationEnd oAnimationEnd MSAnimationEnd", function(){
        $('#sidebar-roles').removeClass('slideinleft');
        $('.sidebar-info').removeClass('d-none');
        $('#sidebar-links').addClass('d-none');

        $('#sidebar-wrapper').removeClass('no-overflow');
      });
      $('#sidebar-roles').addClass('slideinleft');
    }

    return false;
  });

  $('#show-sidebar-links').on('click', function(e) {
    e.preventDefault();

    if(!$('#sidebar-roles').hasClass('slideinleft') && !$('#sidebar-roles').hasClass('slideoutright')) {
      $('#sidebar-links').removeClass('d-none');
      $('.sidebar-info').addClass('d-none');
      $('#sidebar-wrapper').addClass('no-overflow');
      $('#sidebar-roles').one("animationend webkitAnimationEnd oAnimationEnd MSAnimationEnd", function(){
        $('#sidebar-roles').removeClass('slideoutright');
        $('#sidebar-roles').addClass('d-none');

        $('.sidebar-info').removeClass('d-none');
        $('#sidebar-wrapper').removeClass('no-overflow');
      });
      $('#sidebar-roles').addClass('slideoutright');
    }

    return false;
  });

  if( !$('#sidebar-wrapper').hasClass('js-hidden-sb') && $('#sidebar-wrapper').css('visibility') == 'visible' ) {
    (new bootstrap.Offcanvas($('#sidebar-wrapper'))).show();
  }

  $.ajaxSetup({
    headers: {
      'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
    }
  });

  $('#sidebar-wrapper').on('hide.bs.offcanvas', function () {
    $('#sidebar-spacer').css({'transition' : 'width 0.3s ease'});
    $.ajax('/hub/sidebar_hide', { method: 'PUT' });
    Turbolinks.clearCache();
  });

  $('#sidebar-wrapper').on('show.bs.offcanvas', function () {
    $.ajax('/hub/sidebar_show', { method: 'PUT' });
    Turbolinks.clearCache();
  });
});
