$(document).on('turbolinks:load', function(){
  $(document).off('click', '.js-add-field-option');
  $(document).on('click', '.js-add-field-option', function(e) {
    e.preventDefault();

    var template = $(this).data('template');
    var optionHtml = $(template).html().toString();
    $('#field-options').append(optionHtml);
    var addedOption = $('#field-options .field-option input').last();
    $(addedOption).removeAttr('data-parsley-excluded');
    $(addedOption).removeAttr('disabled');
    $(addedOption).parsley({
      errorClass: 'parsley-error',
      successClass: 'parsley-success',
      errorsWrapper: '<div class="invalid-feedback d-block"></div>',
      errorTemplate: '<span></span>'
    });

    if($('#field-options .field-option').length > 7) {
      $('.js-add-field-option').addClass('disabled');
    }

    return false;
  });

  $(document).off('click', '.js-delete-field-option');
  $(document).on('click', '.js-delete-field-option', function(e) {
    e.preventDefault();

    $(this).parents('.field-option').remove();

    if($('#field-options .field-option').length < 8) {
      $('.js-add-field-option').removeClass('disabled');
    }

    return false;
  });

  $('#hub-fields').sortable({
    handle: '.js-field-sort-handle',
    update: function(event, ui) {
      var updateUrl = $(event.target).data('url');
      var params = {
        authenticity_token: $('[name="csrf-token"]')[0].content,
        board_fields: []
      }

      $(event.target).find('.js-sort-field').each(function(i, el) {
        params['board_fields'][i] = $(el).data('id');
      });

      $.ajax({
        method: 'PUT',
        url: updateUrl,
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify(params)
      });
    }
  });
});
