$(document).on('turbolinks:load', function(){
  window.initParsleyForm('#board-information-form');
  window.initParsleyForm('#subject-form');

  var questionParsleyForm;

  var parsleyDefaults = {
    errorClass: 'parsley-error',
    successClass: 'parsley-success',
    errorsWrapper: '<div class="invalid-feedback d-block"></div>',
    errorTemplate: '<span></span>'
  }

  if($('#additional-questions-form').length > 0) {
    questionParsleyForm = $('#additional-questions-form').parsley(parsleyDefaults);
  } else if ($('#goal-questions-form').length > 0) {
    questionParsleyForm = $('#goal-questions-form').parsley(parsleyDefaults);
  }

  var checkRequired = function(section) {
    if($('#no_questions').prop('checked')) {
      $('#' + section + '-questions-form .js-am-field').prop('required', false);
    } else if($('#' + section + '-questions-form .js-question-fields:not(.d-none)').length == 0)  {
      $('#no_questions').prop('required', true);

      questionParsleyForm.destroy();
      questionParsleyForm = $('#' + section + '-questions-form').parsley(parsleyDefaults);
      questionParsleyForm.whenValidate({ group: 'noq' });
    } else {
      $('#' + section + '-questions-form .js-question-fields:not(.d-none) .js-question-field').prop('required', true);
      $('#no_questions').prop('required', false);

      questionParsleyForm.destroy();
      questionParsleyForm = $('#' + section + '-questions-form').parsley(parsleyDefaults);
      questionParsleyForm.whenValidate({ group: 'noq' });
    }
  };

  var checkRequiredAdditionalQuestions = function() {
    checkRequired('additional');
  }

  var checkRequiredGoals = function() {
    checkRequired('goal');
  }

  $('#close-date').datepicker({
    autoclose: true,
    format: 'mm-dd-yyyy',
    startDate: '0d'
  });

  $('#close-date').on('change', function() {
    if( ( $(this).val() != '' ) && $('#launch-button').data('ready') ) {
      $('#launch-button').prop('disabled', false);
      $(this).css('width', '9ch');
    } else {
      $('#launch-button').prop('disabled', true);
      $(this).css('width', '11ch');
    }
  });

  window.Parsley.on('form:validated', function() {
    if( $('.js-board-data-field input.error').length > 0 ) {
      $('#update-board-data-btn').prop('disabled', true);
    } else {
      $('#update-board-data-btn').prop('disabled', false);
    }
  });

  $(document).off('click', '#editing-section-additional .js-add-question');  
  $(document).on('click', '#editing-section-additional .js-add-question', function(e) {
    $('#no_questions').prop('disabled', true);
    checkRequiredAdditionalQuestions();
  });

  $(document).off('click', '#editing-section-additional .js-remove-question');  
  $(document).on('click', '#editing-section-additional .js-remove-question', function(e) {
    if($('#additional-questions-form .js-question-fields:not(.d-none)').length == 0) $('#no_questions').prop('disabled', false);

    checkRequiredAdditionalQuestions();
  });

  $(document).off('change', '.js-question-field');  
  $(document).on('change', '.js-question-field', function(e) {
    if($(this).val() != '') $('#no_questions').prop('checked', false);
    checkRequiredAdditionalQuestions();
  });

  $(document).off('change', '#editing-section-additional #no_questions');
  $(document).on('change', '#editing-section-additional #no_questions', checkRequiredAdditionalQuestions);

  $(document).off('click', '#editing-section-goal .js-add-question');  
  $(document).on('click', '#editing-section-goal .js-add-question', function(e) {
    $('#no_questions').prop('disabled', true).prop('checked', false);
    checkRequiredGoals();
  });

  $(document).off('click', '#editing-section-goal .js-remove-question');  
  $(document).on('click', '#editing-section-goal .js-remove-question', function(e) {
    if($('#goal-questions-form .js-question-fields:not(.d-none)').length == 0) $('#no_questions').prop('disabled', false);

    $('#section-questions .js-question-fields.d-none').appendTo("#deleted-questions");

    checkRequiredGoals();
  });

  $(document).off('change', '.js-question-field');  
  $(document).on('change', '.js-question-field', function(e) {
    if($(this).val() != '') $('#no_questions').prop('checked', false);
    checkRequiredGoals();
  });

  $(document).off('change', '#editing-section-goal #no_questions');
  $(document).on('change', '#editing-section-goal #no_questions', checkRequiredGoals);

  $('#participant-list').on('shown.bs.collapse', function () {
    $('.active-participants-box').addClass('expanded-list');
  })

  $('#participant-list').on('hidden.bs.collapse', function () {
    $('.active-participants-box').removeClass('expanded-list');
  })

  window.initActiveParticipantForm = function(formSelector) {
    var $form = $(formSelector);
    var submit = $(formSelector + ' button[type=submit]');
    var parsleyForm = $form.parsley({
      errorClass: 'parsley-error',
      successClass: 'parsley-success',
      errorsWrapper: '<div class="invalid-feedback d-block"></div>',
      errorTemplate: '<span></span>'
    });

    window.initCustomSelectValidation(formSelector, parsleyForm);

    parsleyForm.on('form:validated', function(form) {
      if(form.validationResult) {
        submit.prop('disabled', false);
      } else {
        submit.prop('disabled', true);
      }

      return true;
    });

    $form.on('change', function() {
      parsleyForm.validate();
      return true;
    });

    $form.find('.form-control').on('change', function(_e) {
      $(this).parent('.form-group').removeClass('hidden-error');
    });

    parsleyForm.on('form:submit', function(form) {
      parsleyForm.destroy();
      Rails.fire($form[0], 'submit');

      return false;
    });
  }

  $(document).off('change', '.js-limit-input');
  $(document).on('change', '.js-limit-input', function() {
    var limit = $(this).data('limit');
    var submit = $(this).data('submit');

    if( $('.js-limit-input:checked').length == limit ) {
      $('.js-limit-input:not(:checked)').prop('disabled', true);
    } else {
      $('.js-limit-input').prop('disabled', false);
    }

    if( $('.js-limit-input:checked').length > 0 ) {
      $(submit).prop('disabled', false);
    } else {
      $(submit).prop('disabled', true);
    }
  });

  $(document).off('change', '#update-close-date-form');
  $(document).on('change', '#update-close-date-form', function() {
    Rails.fire($(this)[0], 'submit');
    return true;
  });

  $('#reports-modal').on('hidden.bs.modal', function (e) {
    $('#reports-modal input[type=checkbox]').prop('checked', false);
    $('#reports-modal input[type=checkbox]').prop('disabled', false);
  });

  var $groupsForm = $('#groups-form');
  $groupsForm.parsley({
    errorClass: 'parsley-error',
    successClass: 'parsley-success',
    errorsWrapper: '<div class="invalid-feedback d-block"></div>',
    errorTemplate: '<span></span>'
  });

  $(document).off('click', '.js-add-ceo-group');
  $(document).on('click', '.js-add-ceo-group', function(e) {
    e.preventDefault();

    var groupForm = $('#new-custom-group').html();
    $('#groups').append(groupForm.replace( /__NEW_ID__/g, Date.now().toString() ));

    return false;
  });

  $(document).off('click', '.js-delete-ceo-group');
  $(document).on('click', '.js-delete-ceo-group', function(e) {
    e.preventDefault();
    var group = $(this).data('group');

    $('#group-' + group + ' .js-ceo-group-destroy').val('1');
    $('#group-' + group + ' input').prop('required', false);
    $('#group-' + group).addClass('d-none');

    return false;
  });

  $('#editing-section-goal .js-question-field').each(function(_i, el) {
    TinyMCERails.initialize('default', {
      selector: '#' + $(el).prop('id'),
      toolbar: 'bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | styleselect | bullist numlist link | undo redo',
      plugins: ['lists', 'paste', 'autoresize', 'link'],
      skin: 'gsw',
      menubar: false,
      pasteAsText: true,
      setup: function (editor) {
        editor.on('change', function () {
          tinymce.triggerSave();
        });
  
        editor.on('mouseenter', function() {
          $(editor.editorContainer).addClass('hover');
        });

        editor.on('mouseleave', function() {
          $(editor.editorContainer).removeClass('hover');
        });

        editor.on('focus', function() {
          $(editor.editorContainer).addClass('in-focus');
        });
  
        editor.on('blur', function() {
          $(editor.editorContainer).removeClass('in-focus');
        });
      }
    });
  });
});
