window.ONBOARDING = window.ONBOARDING || {};
window.ONBOARDING.partialRenderStrings = {};
window.ONBOARDING.topics = {};
window.ONBOARDING.toLoad = {
  subtopic: {},
  contentItem: {}
}
window.ONBOARDING.isSuperadmin = false;
window.ONBOARDING.CSSClassName = window.ONBOARDING.isSuperadmin == true ? ".onboardingSuperadmin" : ".onboarding";
window.ONBOARDING.onSubtopicLoadedCallback = function(){};
window.ONBOARDING.onTopicLoadedCallback = function(){};
window.ONBOARDING.onContentItemLoadedCallback = function(){};
window.ONBOARDING.onSubtopicIncludedExcludedCallback = function(){};
window.ONBOARDING.onTextChangedCallback = function(){};


var loadedTextEditors = [];

window.ONBOARDING.topicSelector = "#onboarding-topic-";
window.ONBOARDING.topicTitleSelector = window.ONBOARDING.topicSelector + "title-";
window.ONBOARDING.topicSubmitSelector = window.ONBOARDING.topicSelector + "submit-";
window.ONBOARDING.topicDeleteSelector = window.ONBOARDING.topicSelector + "delete-";
window.ONBOARDING.topicEditSelector = window.ONBOARDING.topicSelector + "edit-";
window.ONBOARDING.topicCancelSelector = window.ONBOARDING.topicSelector + "cancel-";
window.ONBOARDING.topicAddSubtopicSelector = window.ONBOARDING.topicSelector + "add-subtopic-";
window.ONBOARDING.topicSubtopicsListSelector = window.ONBOARDING.topicSelector + "subtopics-list";

window.ONBOARDING.contentTypeEnum = {
  link: "0",
  pdf: "1",
  text: "2",
  date: "3"
}



window.ONBOARDING.Topic = function(id, order, isNew = true)
{
  //topic class for tracking
  var self = this;
  self.id = id || window.ONBOARDING.nextTopicNumber();
  self.order = order || window.ONBOARDING.nextTopicNumber();
  self.isNew = isNew;
  self.subtopics = {};
  self.deletedSubtopicIDs = [];

  if (self.isNew == true)
  {
    self.id = "new-" + self.id;
  }

  self.validateTopic = function()
  {
    var valid = true;
    for (var subtopicId in self.subtopics)
    {
      if (self.subtopics[subtopicId].validateSubtopic() == false)
      {
        window.ONBOARDING.toggleSubmitClass(self.id, false);
        valid = false;
        break;
      }
      else
      {
        window.ONBOARDING.toggleSubmitClass(self.id);
        valid = true;
      }
    }

    var title = $(window.ONBOARDING.topicTitleSelector + self.id).val();
    if (title && title.length == 0)
    {
      window.ONBOARDING.toggleSubmitClass(self.id, false);
      valid = false;
    }

    return valid;
  }

  self.onLoad = function()
  {
    $(window.ONBOARDING.topicTitleSelector + self.id).on('keyup', function(e){
      var val = e.target.value;

      if (val.length > 0)
      {
          window.ONBOARDING.toggleSubmitClass(self.id);
      }
      else
      {
          window.ONBOARDING.toggleSubmitClass(self.id, false);
      }   
    })

    $(window.ONBOARDING.topicDeleteSelector + self.id).click(function(e){
      e.preventDefault();
      window.ONBOARDING.showDeleteModal(self.id, "Topic", deleteTopic);
    });

    $(window.ONBOARDING.topicCancelSelector + self.id).click(function(e){
      e.preventDefault();
      self.cancelChanges();

      if (self.isNew == true)
      {
        window.ONBOARDING.deleteTopic(self.id);
      }
      else
      {
        window.ONBOARDING.toggleEditMode(self.id, false);
      }
    });

    $(window.ONBOARDING.topicAddSubtopicSelector + self.id).click(function(e){
      e.preventDefault();
      self.addSubtopic();
    });

    if (self.isNew == false)
    {
      //button for switching to edit mode from show
      $(window.ONBOARDING.topicEditSelector + self.id).click(function(e){
        e.preventDefault();
        window.ONBOARDING.toggleEditMode(self.id, true);
        self.validateTopic();
        self.recalculateSubtopicOrder(true, true);
      });
    }

    //on submit, switch mode to show, for editing only
    $(window.ONBOARDING.topicSubmitSelector + self.id).click(function(e){
      e.preventDefault();

      //if we switch to use jquery ajax to handle submit instead of rails forms, will need to call an ajax method
      self.saveTopic();
      window.ONBOARDING.toggleEditMode(self.id, false);
    });

    $(window.ONBOARDING.topicSubtopicsListSelector + self.id + "-show").sortable({
      handle: ".js-sortable-handle",
      update: self.handleSortingSubtopics
    });

    self.loadSubtopicsUI();
  }

  self.handleSortingSubtopics = function()
  {
    var order = 1;
    $(window.ONBOARDING.topicSubtopicsListSelector + self.id + "-show").find(window.ONBOARDING.CSSClassName + "--TopicBodySubtopic").each(function(){
      var subtopicId = $(this).data("id");
      self.subtopics[subtopicId].order = order;
      this.setAttribute("data-order", order);
      order++;
    });

    $(window.ONBOARDING.topicSubtopicsListSelector + self.id + "-edit").find(window.ONBOARDING.CSSClassName + "--Subtopic").each(function(){
      var subtopicId = $(this).data("id");
      this.setAttribute("data-order", self.subtopics[subtopicId].order);
    });

    $.ajax({
            url: "<%= save_reorder_superadmin_hub_tools_onboarding_onboarding_subtopics_path(@hub) %>",
            type: "PUT",
            data: {subtopics: self.getSubtopicData()}
        });
  }

  self.loadSubtopicsUI = function()
  {
    var subtopics = window.ONBOARDING.toLoad["subtopic"][self.id] || [];

    for (let i = 0; i < subtopics.length; ++i)
    {
      var data = subtopics[i];
      window.ONBOARDING.loadSubtopic(data);
    }
  }

  self.getSubtopicData = function()
  {
    var subtopics = [];

    for (var stid in self.subtopics)
    {
      var subtopic = self.subtopics[stid];
      var selector = "onboarding-topic-"+subtopic.topicID+"-subtopic-"+subtopic.id;
      var title = $("#"+selector+"-title").val();
      var amInstructions = $("#"+selector+"-am-instructions").val();
      var order = subtopic.order;
      var global = $("#"+selector+"-per-board-switch").is(":checked");
      var editable = $("#"+selector+"-editable-switch").is(":checked");
      var include = $("#"+selector+"-include-switch").is(":checked");
      var description = $("#"+selector+"-description").val();
      var contentType = $("input:radio[name='subtopics[topic-"+subtopic.topicID+"-subtopic-"+subtopic.id+"][content_type]']:checked").val()
      var contentItems = self.getSubtopicContentData(subtopic);

      if (window.ONBOARDING.isSuperadmin == false)
      {
        if ((subtopic.isNew == true || subtopic.isCustom == true))
        {
          global = false;
          editable = true;
          include = true;
        }
        else
        {
          global = subtopic.isGlobal;
          editable = subtopic.isEditable;
        }

        if (subtopic.newID)
        {
          //since we allow saving, new subtopics don't have an id, so we set newid on saving, but we need to tie the new id to the subtopic
          subtopic.id = subtopic.newID;
        }
      }

      var deletedContentItems = [];
      for (let j = 0; j < subtopic.deletedContentItems.length; ++j)
      {
        var item = subtopic.deletedContentItems[j];
        deletedContentItems.push({id: item.id, content_type: item.contentType});
      }

      var data = {
          onboarding_topic_id: subtopic.topicID, 
          title: title, am_instructions: amInstructions, 
          order: order, 
          global: global, 
          is_new: subtopic.isNew,
          editable: editable,
          description: description,
          content_type: contentType,
          content_items: contentItems,
          id: subtopic.id,
          deleted_content_items: deletedContentItems,
          is_valid: subtopic.isValid,
          include: include
        };

      subtopics.push(data);
    }

    return subtopics;
  }

  self.getSubtopicContentData = function(subtopic)
  {
    var contentItems = [];
    var selector = subtopic.getSelector();
    var order = 1;
    var className = ".onboarding";

    if (window.ONBOARDING.isSuperadmin == true)
    {
      className += "Superadmin";
    }

    switch(subtopic.contentType)
    {
      case window.ONBOARDING.contentTypeEnum.link:
        var linkSelector = selector + "-link-";
        $(selector).find(className + "--SubtopicLink").each(function(){
          var el = $(this);
          var id = el.data("id");
          var is_new = el.data("is-new");
          var url = $(linkSelector+id+"-url").val();
          var label = $(linkSelector+id+"-label").val();
          var item = subtopic.links.find(function(item){
            return item.id == id;
          });

          if (item && item.newID)
          {
            id = item.newID;
            is_new = false;
          }

          var data = {
            id: id,
            is_new: is_new,
            label: label,
            url: url,
            order: order,
            content_type: window.ONBOARDING.contentTypeEnum.link
          }

          order++;
          contentItems.push(data);
        })
        break;
      case window.ONBOARDING.contentTypeEnum.pdf:
        var linkSelector = selector + "-pdf-";
        $(selector).find(className + "--SubtopicPDF").each(function(){
          var el = $(this);
          var id = el.data("id");
          var is_new = el.data("is-new");

          var item = subtopic.pdfs.find(function(item){
            return item.id == id;
          });

          if (item && item.newID)
          {
            id = item.newID;
            is_new = false;
          }

          var data = {
            id: id,
            is_new: is_new,
            order: order,
            content_type: window.ONBOARDING.contentTypeEnum.pdf
          }

          order++;
          contentItems.push(data);
        })
        break;
      case window.ONBOARDING.contentTypeEnum.text:
        var linkSelector = selector + "-text-content";
        var el = $(linkSelector);
        var id = el.data("id");
        var data = {
          id: el.data("id"),
          is_new: el.data("is-new"),
          content: $(linkSelector).val(),
          content_type: window.ONBOARDING.contentTypeEnum.text
        }

        contentItems.push(data);
        break;
      case window.ONBOARDING.contentTypeEnum.date:
        var dateSelector = selector + "-date-";
        $(selector).find(className + "--SubtopicDate").each(function(){
          var el = $(this);
          var id = el.data("id");
          var is_new = el.data("is-new");
          var label = $(dateSelector+id+"-label").val();
          var startDate = $(dateSelector+id+"-start-date").val();
          var endDate = $(dateSelector+id+"-end-date").val();
          var isSingleDate = $(dateSelector+id+"-single-date").is(":checked");
          var item = subtopic.dates.find(function(item){
            return item.id == id;
          });

          if (item && item.newID)
          {
            id = item.newID;
            is_new = false;
          }

          var data = {
            id: id,
            is_new: is_new,
            label: label,
            start_date: startDate,
            end_date: endDate,
            is_single_date: isSingleDate,
            order: order,
            content_type: window.ONBOARDING.contentTypeEnum.date
          }

          order++;
          contentItems.push(data);
        })
        break;
      default:
        break;
    }

    return contentItems;
  }

  self.getTopicData = function()
  {
    var selector = "onboarding-topic-"+self.id;
    var title = $(window.ONBOARDING.topicTitleSelector+self.id).val();
    var order = self.order;
    var amInstructions = $("#"+selector+"-am-instructions").val();
    var bmInstructions = $("#"+selector+"-bm-instructions").val();

    return {id: self.id, title: title, order: order, am_instructions: amInstructions, bm_instructions: bmInstructions, is_new: self.isNew};
  }

  self.saveTopic = function()
  {
    var valid = self.validateTopic();
    if (valid == true)
    {
      var payload = {topic_id: self.id, topic_data: self.getTopicData(), subtopics: self.getSubtopicData(), deleted_subtopic_ids: self.deletedSubtopicIDs };
      //ajax to save/update in bulk
      var url = '<%= superadmin_hub_tools_onboarding_onboarding_topics_path(@hub) %>'

      $.ajax({
        url: url,
        data: payload,
        method: self.isNew == true ? "POST" : "PUT",
        success: self.saveTopicFiles
      })
    }
  }

  self.saveTopicFiles = function(newSubtopicIDs)
  {
    var formData = new FormData();
    var numfilesToSave = 0;

    for (var st in self.subtopics)
    {
      var subtopic = self.subtopics[st];

      for (var j = 0; j < newSubtopicIDs.length; j++)
      {
        var newSubtopicID = newSubtopicIDs[j];
        if (subtopic.id == newSubtopicID.old_id)
        {
          subtopic.id = newSubtopicID.new_id;
          break;
        }
      }

      formData.append("subtopic_id", subtopic.id);

      if (subtopic.files.length == 0)
      {
        continue;
      }
      else
      {
        numfilesToSave++;
      }
      
      for (var j = 0; j < subtopic.files.length; j++)
      {
        formData.append("files["+subtopic.id+"]["+subtopic.files[j].item_id+"]", subtopic.files[j].file);
      }

      for (var k = 0; k < subtopic.pdfs.length; ++k)
      {
        formData.append("order_map["+subtopic.id+"]["+subtopic.pdfs[k].id+"]", subtopic.pdfs[k].order);
      }
    }

    if (numfilesToSave > 0)
    {
      $.ajax({
        url: '<%= upload_files_superadmin_hub_tools_onboarding_onboarding_subtopics_path(@hub) %>',
        data: formData,
        method: "POST",
        processData: false,
        contentType: false,
        success: function()
        {
          window.location.replace("<%= superadmin_hub_tools_onboarding_onboarding_blueprints_path(@hub) %>");
        } 
      })
    }
    else
    {
      window.location.replace("<%= superadmin_hub_tools_onboarding_onboarding_blueprints_path(@hub) %>");
    }
  }

  self.addSubtopic = function(e, id, order, isNew = true, isGlobal = false, isEditable = true, isCustom = false, isCompleted = false, isIncluded = true)
  {
    var id = id || self.nextSubtopicNumber();
    var order = order || self.nextSubtopicNumber();
    var subtopic = new window.ONBOARDING.Subtopic(self.id, id, order, isNew, isGlobal, isEditable, isCustom, isCompleted, isIncluded);
    if (isNew == true)
    {
      subtopic.id = "new-subtopic-" + subtopic.id;
      self.appendNewSubtopicForm(subtopic);
    }

    self.subtopics[subtopic.id] = subtopic;
  }

  self.loadSubtopic = function(id)
  {
    self.subtopics[id].onLoad();
  }

  self.nextSubtopicNumber = function()
  {
    return Object.keys(self.subtopics).length + 1;
  }

  self.appendNewSubtopicForm = function(subtopicData)
  {
    $(window.ONBOARDING.topicSubtopicsListSelector + subtopicData.topicID + "-edit").append(getNewSubtopicPartial(subtopicData));
  }

  self.recalculateSubtopicOrder = function(sort = false, reorderUI = false)
  {
    var order = 1;
    var subtopics = self.subtopics;

    if (sort == true)
    {
      subtopics = Object.values(subtopics).sort(function(a, b) {
        var orderA = parseInt(a.order);
        var orderB = parseInt(b.order);
        return orderA - orderB;
      });
    }

    for (var id in subtopics)
    {
      var subtopic = subtopics[id];
      subtopic.setOrder(order);
      order++;
    }
    if (reorderUI == true)
    {
      var container = $(window.ONBOARDING.topicSubtopicsListSelector + self.id + "-edit");
      var divs = container.children(window.ONBOARDING.CSSClassName + '--Subtopic');
      divs.sort(function(a, b) {
        var orderA = parseInt($(a).data('order'));
        var orderB = parseInt($(b).data('order'));
        return orderA - orderB;
      });
      divs.detach().appendTo(container);
      self.refreshSubtopicTextEditors();
    }
  }

  self.refreshSubtopicTextEditors = function()
  {
    for (var id in self.subtopics)
    {
      var subtopic = self.subtopics[id];
      subtopic.refreshTinyMCE();
    }
  }

  self.cancelChanges = function()
  {
    window.ONBOARDING.cancelTopicChanges(self.id);
    self.validateTopic();
    for (var st in self.subtopics)
    {
      var subtopic = self.subtopics[st];
      subtopic.setCheckedContentType();
      self.deletedSubtopicIDs.splice(self.deletedSubtopicIDs.indexOf(subtopic.id), 1);
      subtopic.cancelChanges();
    }
  }
}

window.ONBOARDING.Subtopic = function(topicID, id, order, isNew = true, isGlobal = false, isEditable = false, isCustom = false, isCompleted = false, isIncluded = true)
{
  //subtopic class for tracking
  var self = this;
  self.topicID = topicID || null;
  self.id = id;
  self.newID = null; //set after saving a new subtopic
  self.order = order;
  self.isNew = isNew;
  self.isGlobal = JSON.parse(isGlobal);
  self.isEditable = JSON.parse(isEditable);
  self.isCustom = JSON.parse(isCustom);
  self.isCompleted = JSON.parse(isCompleted);
  self.isIncluded = JSON.parse(isIncluded);
  self.isHidden = false;
  self.isValid = true;
  self.state = []; //track changes to subtopic for cancel functionality
  self.lastContentType = null;
  self.contentType = null;
  self.contentItems = [];
  self.deletedContentItems = [];
  self.pdfs = [];
  self.links = [];
  self.dates = [];
  self.files = [];
  self.lastPDFFileName = "";

  self.validateParentTopic = function()
  {
   window.ONBOARDING.topics[self.topicID].validateTopic(); 
  }

  self.validateSubtopic = function()
  {
    var valid = true;

    var title = $(self.getSelector()+"-title").val();
    if (title && title.trim() == "")
    {
      valid = false;
    }

    if (self.isGlobal == true && self.contentType == null)
    {
      valid = false;
    }

    if (self.contentType != null)
    {
      switch(self.contentType)
      {
        case window.ONBOARDING.contentTypeEnum.link:
          var linkSelector = self.getSelector() + "-link-";
          $(self.getSelector()).find(window.ONBOARDING.CSSClassName + "--SubtopicLink").each(function(){
            var el = $(this);
            var deleted = el.data("deleted");
            if (deleted)
            {
              return true;
            }
            var id = el.data("id");
            var url = $(linkSelector+id+"-url").val();
            var label = $(linkSelector+id+"-label").val();

            if (url.trim() == "" || label.trim() == "")
            {
              valid = false;
              return false;
            }
          })
          break;
        case window.ONBOARDING.contentTypeEnum.pdf:
          var pdfSelector = self.getSelector() + "-pdf-";
          $(self.getSelector()).find(window.ONBOARDING.CSSClassName + "--SubtopicPDF").each(function(){
            var el = $(this);
            var deleted = el.data("deleted");
            if (deleted)
            {
              return true;
            }
            var id = el.data("id");
            var hasFile = el.data("has-file");
            if (hasFile == false)
            {
              valid = false;
              return false;
            }
          })

          if (self.pdfs.length == 0)
          {
            valid = false;
            return false;
          }
          break;
        case window.ONBOARDING.contentTypeEnum.text:
          var textSelector = self.getSelector() + "-text-content";
          var text = $(textSelector).val();
          if ($(textSelector).length > 0 && text.trim() == "")
          {
            valid = false;
            return false;
          }
          break;
        case window.ONBOARDING.contentTypeEnum.date:
          var dateSelector = self.getSelector() + "-date-";
          $(self.getSelector()).find(window.ONBOARDING.CSSClassName + "--SubtopicDate").each(function(){
            var el = $(this);
            var deleted = el.data("deleted");
            if (deleted)
            {
              return true;
            }
            var id = el.data("id");
            var label = $(dateSelector+id+"-label").val();
            var startDate = $(dateSelector+id+"-start-date").val();
            var endDate = $(dateSelector+id+"-end-date").val();
            var isSingleDate = $(dateSelector+id+"-single-date").is(":checked");

            if (label.trim() == "")
            {
              valid = false;
              return false;
            }

            if (isSingleDate == true && startDate.trim() == "")
            {
              valid = false;
              return false;
            }

            if (isSingleDate == false && (startDate.trim() == "" || endDate.trim() == ""))
            {
              valid = false;
              return false;
            }
          })
          break;
      }
    }

    

    return valid;
  }

  self.setContentType = function(contentType)
  {
    self.state.push({contentType: self.contentType}); //current contentType
    self.contentType = contentType;
    self.toggleContentTypeSection(contentType);
  }

  self.getSelector = function(includeHashtag = true)
  {
    var sel = "onboarding-topic-"+self.topicID+"-subtopic-"+self.id;

    if (includeHashtag == true)
    {
      sel = "#"  + sel;
    }

    return sel;
  }

  self.onLoad = function()
  {
    var id = self.id;
    self.isGlobal = $(self.getSelector() + "-per-board-switch").prop("checked");
    $(self.getSelector() + "-per-board-switch").change(function(e){
      e.preventDefault();
      self.isGlobal = this.checked;
      self.toggleContentSection(this.checked);
      if (self.isGlobal == false)
      {
        self.contentType = null;
      }
      self.validateParentTopic();
    });
    
    if (self.isEditable == false)
    {
      $(self.getSelector()+"-title").on('keyup', function(e){
        self.validateParentTopic();
      })

      if (self.isNew == true)
      {
        window.ONBOARDING.toggleSubmitClass(self.topicID, false);
      }

      self.disableSubtopic();
    }
    else
    {
      $(self.getSelector() + "-delete").click(function(e){
        e.preventDefault();
        window.ONBOARDING.showDeleteModal(self.id, "Subtopic", self.delete);
      });

      // content types
      $(self.getSelector() + "-add-link").click(function(e){
        e.preventDefault();
        self.addLink();
        self.validateParentTopic();
      });

      $(self.getSelector() + "-add-date").click(function(e){
        e.preventDefault();
        self.addDate();
        self.validateParentTopic();
      });

      $(self.getSelector() + "-add-pdf").click(function(e){
        e.preventDefault();
        self.addPDF();
        self.validateParentTopic();
      });

      self.initSortable();
      self.initTinyMCE();

      $("input:radio[name='subtopics[topic-"+self.topicID+"-subtopic-"+self.id+"][content_type]']").click(function(e){
        self.setContentType(this.value);
        self.validateParentTopic();
      })

      $(self.getSelector() + "-include-switch").change(function(e){
        e.preventDefault();
        self.isIncluded = this.checked;
        window.ONBOARDING.onSubtopicIncludedExcludedCallback(self);
      });

    }
    window.ONBOARDING.onSubtopicLoadedCallback(self);

    if (self.isIncluded == false)
    {
      self.disableSubtopic();
    }

    //set initial content type on load of existing data
    self.setCheckedContentType();
    self.loadContentItemsUI();
    self.reorderPDFItems();
    window.ONBOARDING.topics[topicID].recalculateSubtopicOrder(true);
  }

  self.disableSubtopic = function()
  {
    //disable here for non-editable subtopics AM level
    for (let link in self.links)
    {
      self.links[link].disableContentItem();
    }

    for (let pdf in self.pdfs)
    {
      self.pdfs[pdf].disableContentItem();
    }

    for (let date in self.dates)
    {
      self.dates[date].disableContentItem();
    }
    
    var disabledClass = "onboarding--Subtopic-Is-Disabled";
    $(self.getSelector()).find(".onboarding--SubtopicFlexInput").addClass(disabledClass);
    $(self.getSelector()).find(".onboarding--SubtopicTextArea").addClass(disabledClass);
    $(self.getSelector()).find(".onboarding--SubtopicContentType").addClass(disabledClass);
    $(self.getSelector()).find(".onboarding--TopicBodySubtopicIcon").addClass(disabledClass);
    $(self.getSelector()).find(".onboarding--TopicButton").addClass(disabledClass);
    $(self.getSelector()).find(".onboarding--SubtopicToggleSection").addClass(disabledClass);
    $(self.getSelector()).find(".onboarding--SubtopicToggleSectionLabel").addClass(disabledClass);
    $(self.getSelector()).find(".onboarding--SubtopicLabel").addClass(disabledClass);
    $(self.getSelector()).find(".onboarding--SubtopicStatus").addClass(disabledClass);
    $(self.getSelector()).find(".onboarding--SubtopicDateInput").addClass(disabledClass);
    $(self.getSelector()).find(".onboarding--SubtopicPDFInlineDelete").addClass(disabledClass);
    $(self.getSelector()).find(".onboarding--SubtopicPDFLabel").addClass(disabledClass);
    $(self.getSelector()).find(".onboarding--SubtopicText").addClass(disabledClass);
    $(self.getSelector() + "-include-switch-control").removeClass("custom-switch-onboarding-cobalt");
    $(self.getSelector() + "-include-switch-control").addClass("custom-switch-onboarding-cobalt-disabled");
    $(self.getSelector()).find(".tox-tinymce").hide();
    $(self.getSelector() + "-text-content-fake").show();
  }

  self.enableSubtopic = function()
  {
    //enable
    for (let link in self.links)
    {
      self.links[link].enableContentItem();
    }

    for (let pdf in self.pdfs)
    {
      self.pdfs[pdf].enableContentItem();
    }

    for (let date in self.dates)
    {
      self.dates[date].enableContentItem();
    }

    var disabledClass = "onboarding--Subtopic-Is-Disabled";
    $(self.getSelector()).find(".onboarding--SubtopicFlexInput").removeClass(disabledClass);
    $(self.getSelector()).find(".onboarding--SubtopicTextArea").removeClass(disabledClass);
    $(self.getSelector()).find(".onboarding--SubtopicContentType").removeClass(disabledClass);
    $(self.getSelector()).find(".onboarding--TopicBodySubtopicIcon").removeClass(disabledClass);
    $(self.getSelector()).find(".onboarding--TopicButton").removeClass(disabledClass);
    $(self.getSelector()).find(".onboarding--SubtopicToggleSection").removeClass(disabledClass);
    $(self.getSelector()).find(".onboarding--SubtopicToggleSectionLabel").removeClass(disabledClass);
    $(self.getSelector()).find(".onboarding--SubtopicLabel").removeClass(disabledClass);
    $(self.getSelector()).find(".onboarding--SubtopicDateInput").removeClass(disabledClass);
    $(self.getSelector()).find(".onboarding--SubtopicPDFInlineDelete").removeClass(disabledClass);
    $(self.getSelector()).find(".onboarding--SubtopicPDFLabel").removeClass(disabledClass);
    $(self.getSelector()).find(".onboarding--SubtopicText").removeClass(disabledClass);

    if (self.isCompleted == false)
    {
      $(self.getSelector()).find(".onboarding--SubtopicStatus").removeClass(disabledClass);
    }

    $(self.getSelector() + "-include-switch-control").addClass("custom-switch-onboarding-cobalt");
    $(self.getSelector() + "-include-switch-control").removeClass("custom-switch-onboarding-cobalt-disabled");
    $(self.getSelector()).find(".tox-tinymce").show();
    $(self.getSelector() + "-text-content-fake").hide();
  }

  self.collapseAndComplete = function()
  {

  }

  self.loadContentItemsUI = function()
  {
    var items = window.ONBOARDING.toLoad["contentItem"][self.id] || [];

    for (let i = 0; i < items.length; ++i)
    {
      var data = items[i];
      data.subtopicData = self;
      window.ONBOARDING.loadContentItem(data);
    }
  }

  self.toggleContentSection = function(enabled)
  {
    if (enabled == true)
    {
      $(self.getSelector() + "-content-section").show();
    }
    else
    {
      $(self.getSelector() + "-content-section").hide();
    }
  }

  self.toggleContentTypeSection = function(contentType)
  {
    $(self.getSelector() + "-content").show();
    switch(contentType)
    {
      case window.ONBOARDING.contentTypeEnum.link:
        $(self.getSelector() + "-links").show();
        $(self.getSelector() + "-dates").hide();
        $(self.getSelector() + "-text").hide();
        $(self.getSelector() + "-pdfs").hide();

        if (self.isNew == true && self.links.length == 0 || (self.lastContentType != window.ONBOARDING.contentTypeEnum.link && self.links.length == 0))
        {
          self.addLink();
        }
        break;
      case window.ONBOARDING.contentTypeEnum.pdf:
        $(self.getSelector() + "-pdfs").show();
        $(self.getSelector() + "-links").hide();
        $(self.getSelector() + "-dates").hide();
        $(self.getSelector() + "-text").hide();
        break;
      case window.ONBOARDING.contentTypeEnum.text:
        $(self.getSelector() + "-text").show();
        $(self.getSelector() + "-links").hide();
        $(self.getSelector() + "-dates").hide();
        $(self.getSelector() + "-pdfs").hide();
        break;
      case window.ONBOARDING.contentTypeEnum.date:
        $(self.getSelector() + "-dates").show();
        $(self.getSelector() + "-links").hide();
        $(self.getSelector() + "-text").hide();
        $(self.getSelector() + "-pdfs").hide();

        if (self.isNew == true && self.dates.length == 0 || (self.lastContentType != window.ONBOARDING.contentTypeEnum.date && self.dates.length == 0))
        {
          self.addDate();
        }
        break;
    }
    return true;
  }

  self.addLink = function()
  {
    var link = new window.ONBOARDING.SubtopicContentItem(self, null, self.links.length + 1, self.contentType);
    $(self.getSelector() + "-links-list").append(self.getContentLinkPartial(link));
  }

  self.addDate = function()
  {
    var date = new window.ONBOARDING.SubtopicContentItem(self, null, self.dates.length + 1, self.contentType);
    $(self.getSelector() + "-dates-list").append(self.getContentDatePartial(date));
  }

  self.addPDF = function()
  {
    var pdf = new window.ONBOARDING.SubtopicContentItem(self, null, self.pdfs.length + 1, self.contentType);
    $(self.getSelector() + "-pdfs-list").append(self.getContentPDFPartial(pdf));
  }

  self.addContentItem = function(contentItem)
  {
    switch(contentItem.contentType)
    {
      case window.ONBOARDING.contentTypeEnum.link:
        self.links.push(contentItem);
        break;
      case window.ONBOARDING.contentTypeEnum.pdf:
        self.pdfs.push(contentItem);
        break;
      case window.ONBOARDING.contentTypeEnum.date:
        self.dates.push(contentItem);
        break;
    }

    self.recalculateContentItemOrder();
  }

  self.getContentLinkPartial = function(link)
  {
    var renderedFormString = window.ONBOARDING.partialRenderStrings["link"];
    renderedFormString = renderedFormString.replaceAll("_ID_", link.id);
    renderedFormString = renderedFormString.replaceAll("_SUBTOPICID_", link.subtopicData.id);
    renderedFormString = renderedFormString.replaceAll("_SELECTOR_", self.getSelector(false));
    renderedFormString = renderedFormString.replaceAll("_#_", link.order);
    return renderedFormString;
  }

  self.getContentDatePartial = function(date)
  {
    var renderedFormString = window.ONBOARDING.partialRenderStrings["date"];
    renderedFormString = renderedFormString.replaceAll("_ID_", date.id);
    renderedFormString = renderedFormString.replaceAll("_SUBTOPICID_", date.subtopicData.id);
    renderedFormString = renderedFormString.replaceAll("_SELECTOR_", self.getSelector(false));
    renderedFormString = renderedFormString.replaceAll("_#_", date.order);
    return renderedFormString;
  }

  self.getContentPDFPartial = function(pdf)
  {
    var renderedFormString = window.ONBOARDING.partialRenderStrings["pdf"];
    renderedFormString = renderedFormString.replaceAll("_ID_", pdf.id);
    renderedFormString = renderedFormString.replaceAll("_SUBTOPICID_", pdf.subtopicData.id);
    renderedFormString = renderedFormString.replaceAll("_SELECTOR_", self.getSelector(false));
    renderedFormString = renderedFormString.replaceAll("_#_", pdf.order);
    return renderedFormString;
  }

  self.setOrder = function(order)
  {
    self.order = order;
    $(self.getSelector()).data("order", order);
    $(self.getSelector() + "-order").html(order);
  }

  self.recalculateContentItemOrder = function()
  {
    var order = 1;
    var arr = [];
    switch(self.contentType)
    {
      case window.ONBOARDING.contentTypeEnum.link:
        arr = self.links;
        break;
      case window.ONBOARDING.contentTypeEnum.date:
        arr = self.dates;
        break;
    }

    for (var i = 0; i < arr.length; i++)
    {
      arr[i].setOrder(order);
      order++;
    }
  }

  self.reorderPDFItems = function()
  {
    //need something specific for pdfs
    var order = 1;
    $(self.getSelector()).find(window.ONBOARDING.CSSClassName + "--SubtopicPDF:visible").each(function(){
      var el = $(this);
      var id = el.data("id");

      var item = self.pdfs.find(function(item){
        return item.id == id;
      });

      if (item)
      {
        item.setOrder(order);
      }

      $("#" + this.id + "-order").html(order);
      
      order++;
    })
  }

  self.getNextPDFOrder = function()
  {
    var order = 1;
    $(self.getSelector()).find(window.ONBOARDING.CSSClassName + "--SubtopicPDF:visible").each(function(){
      order++;
    })

    return order;
  }

  self.setCheckedContentType = function()
  {
    var ct = $("input:radio[name='subtopics[topic-"+self.topicID+"-subtopic-"+self.id+"][content_type]']:checked").val();
    if (ct)
    {
      self.lastContentType = ct;
      self.setContentType(ct);
    }
  }

  self.delete = function()
  {
    if (self.isNew == false)
    {
     window.ONBOARDING.topics[self.topicID].deletedSubtopicIDs.push(self.id);
      //hide UI
      self.isHidden = true;
      window.ONBOARDING.hideSubtopic(self);
    }
    else
    {
      //remove UI
      window.ONBOARDING.deleteSubtopic(self);
    }
   window.ONBOARDING.topics[self.topicID].recalculateSubtopicOrder();
    self.validateParentTopic();
  }

  self.deleteContentItem = function(item)
  {
      if (item.isNew == false)
      {
        self.deletedContentItems.push(item);
        //hide UI
        window.ONBOARDING.hideContentItem(item);
      }
      else
      {
        //remove UI
        window.ONBOARDING.deleteContentItem(item);

        switch(item.contentType)
        {
          case window.ONBOARDING.contentTypeEnum.link:
            self.links.splice(self.links.indexOf(item), 1);
            break;
          case window.ONBOARDING.contentTypeEnum.pdf:
            self.pdfs.splice(self.pdfs.indexOf(item), 1);
            break;
          case window.ONBOARDING.contentTypeEnum.date:
            self.dates.splice(self.dates.indexOf(item), 1);
            break;
        }
      }


      self.recalculateContentItemOrder();
      self.reorderPDFItems();
  }

  self.initSortable = function()
  {
    $(self.getSelector() + "-links-list").sortable({
      handle: ".js-sortable-handle"
    });

    $(self.getSelector() + "-dates-list").sortable({
      handle: ".js-sortable-handle"
    });

    $(self.getSelector() + "-pdfs-list").sortable({
      handle: ".js-sortable-handle",
      update: function(event, ui) {
        self.reorderPDFItems();
      }
    });
  }

  self.initTinyMCE = function()
  {
    //may need to replace with trumbo
    TinyMCERails.initialize('default', {
      selector: self.getSelector() + "-text-content",
      toolbar: 'bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | styleselect | bullist numlist link | undo redo',
      plugins: ['lists', 'paste', 'autoresize', 'link'],
      // skin: 'gsw',
      menubar: false,
      pasteAsText: true,
      setup: function(editor) {
        editor.on('change', function() {
          tinymce.triggerSave();
          self.validateParentTopic();
          window.ONBOARDING.onTextChangedCallback();
        });
  
        editor.on('mouseenter', function() {
          $(editor.editorContainer).addClass('hover');
        });

        editor.on('mouseleave', function() {
          $(editor.editorContainer).removeClass('hover');
        });

        editor.on('focus', function() {
          $(editor.editorContainer).addClass('in-focus');
        });
  
        editor.on('blur', function() {
          $(editor.editorContainer).removeClass('in-focus');
        });
      }
    });
  }

  self.refreshTinyMCE = function()
  {
    tinymce.remove(self.getSelector() + "-text-content");
    self.initTinyMCE();
  }

  self.handleFiles = function(files, pdf, url){
    var formData = new FormData()
    var xhr = new XMLHttpRequest()
    xhr.open('POST', url, true)
    xhr.withCredentials = true;
    xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));

    xhr.upload.addEventListener('progress', function(e) {
      // $('#pdf-' + pdf + '-progress').css('width', ((e.loaded * 100.0 / e.total) || 100).toString() + '%');
    })

    xhr.addEventListener('readystatechange', function(e) {
      if (xhr.readyState == 4 && xhr.status == 200) {
        var data = JSON.parse(xhr.responseText);
        if (data)
        {
          data.lastPDFFileName = self.lastPDFFileName;
          for (let i = 0; i < self.pdfs.length; i++) {
            if (self.pdfs[i].id == data.id)
            {
              self.pdfs[i].showPDFFileDisplay(data); 
              break;
            }
          }
        }
      }
      else if (xhr.readyState == 4 && xhr.status != 200) {
        // Error. Inform the user
      }
    })

    self.lastPDFFileName = files[0].name;
    formData.append('pdf_file', files[0])
    formData.append('item_id', pdf);
    formData.append('subtopic_id', self.id);
    formData.append('old_id', pdf);
    self.files.push({file: files[0], item_id: pdf});


    //instead of sending an xhr request, just update the pdf display
    //saving files will be handled in the controller
    for (let i = 0; i < self.pdfs.length; i++) {
      if (self.pdfs[i].id == pdf)
      {
        var data = {url: "#", lastPDFFileName: self.lastPDFFileName};
        self.pdfs[i].showPDFFileDisplay(data); 
        break;
      }
    }

    // $(self.getSelector() + '.ob-pdf-accept').addClass('d-none');
    // $('#pdf-' + pdf + '-form .ob-pdf-uploading').removeClass('d-none');

    // $('#pdf-' + pdf + '-form .ob-pdf-uploading h6').text(files[0].name + ' (' + onboarding.formatBytes(files[0].size, 2) + ')')

    // xhr.send(formData)
  };

  self.formatBytes = function(bytes, decimals) {
    if (bytes === 0) return '0 Bytes';

    var k = 1024;
    var dm = decimals < 0 ? 0 : decimals;
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    var i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  self.cancelChanges = function()
  {
    self.isHidden = false;
    window.ONBOARDING.showSubtopic(self);

    for (let i = 0; i < self.deletedContentItems.length; ++i)
    {
      var item = self.deletedContentItems[i];
      item.isHidden = false;
      window.ONBOARDING.showContentItem(item);
      self.deletedContentItems.splice(i, 1);
    }

    $(self.getSelector() + "-links-list").sortable('cancel');

    $(self.getSelector() + "-dates-list").sortable('cancel');

    $(self.getSelector() + "-pdfs-list").sortable('cancel');

    self.recalculateContentItemOrder();
    self.reorderPDFItems();
  }
}

window.ONBOARDING.SubtopicContentItem = function(subtopicData, id, order, contentType, isNew = true)
{
  var self = this;
  self.subtopicData = subtopicData;
  self.topicID = subtopicData.topicID;
  self.subtopicID = subtopicData.id;
  self.id = id || order;
  self.order = order;
  self.isNew = isNew;
  self.isHidden = false;
  self.contentType = contentType;
  self.newID = null;

  if (self.contentType == window.ONBOARDING.contentTypeEnum.pdf)
  {
    self.order = subtopicData.getNextPDFOrder();
  }

  self.getSelector = function(includeHashtag = true)
  {
    var sel = "onboarding-topic-"+self.topicID+"-subtopic-"+self.subtopicID;

    switch(self.contentType)
    {
      case window.ONBOARDING.contentTypeEnum.link:
        sel = sel + "-link-" + self.id;
        break;
      case window.ONBOARDING.contentTypeEnum.pdf:
        sel = sel + "-pdf-" + self.id;
        break;
      case window.ONBOARDING.contentTypeEnum.text:
        sel = sel + "-text-" + self.id;
        break;
      case window.ONBOARDING.contentTypeEnum.date:
        sel = sel + "-date-" + self.id;
        break;
    }

    if (includeHashtag == true)
    {
      sel = "#"  + sel;
    }

    return sel;
  }

  self.setOrder = function(order)
  {
    self.order = order;
    $(self.getSelector() + "-order").html(order);
  }

  self.onLoad = function()
  {
    if (self.subtopicData.isEditable == true)
    {
      $(self.getSelector() + "-delete").click(function(){
        self.isHidden = true;
        self.subtopicData.deleteContentItem(self);
        self.subtopicData.validateParentTopic();
      });


      switch(self.contentType)
      {
        case window.ONBOARDING.contentTypeEnum.link:
          $(self.getSelector()+"-label").on('keyup', function(e){
              self.subtopicData.validateParentTopic();
          });

          $(self.getSelector()+"-url").on('keyup', function(e){
              self.subtopicData.validateParentTopic();
          });
          break;
        case window.ONBOARDING.contentTypeEnum.date:
          $(self.getSelector() + "-single-date").change(function(){
            self.toggleSingleDate(this.checked);
            self.subtopicData.validateParentTopic();
          });
          var is_single_date = $(self.getSelector() + "-single-date").is(":checked");
          self.toggleSingleDate(is_single_date);

          $(self.getSelector()+"-label").on('keyup', function(e){
              self.subtopicData.validateParentTopic();
          });

          $(self.getSelector()+"-start-date").datepicker({ autoclose: true});

          $(self.getSelector()+"-start-date").change(function(e){
              var minDate = $(this).val();
              $(self.getSelector()+"-end-date").datepicker('setStartDate', minDate);
              self.subtopicData.validateParentTopic();

          });

          $(self.getSelector()+"-end-date").datepicker({startDate: new Date(), autoclose: true});
          $(self.getSelector()+"-end-date").change(function(e){
              self.subtopicData.validateParentTopic();
          });
          break;
        case window.ONBOARDING.contentTypeEnum.pdf:
          $(self.getSelector() + "-delete-inline").click(function(){
            self.subtopicData.deleteContentItem(self);
            self.subtopicData.validateParentTopic();
          });
          break;
      }

      self.initDropzone();
    }
      window.ONBOARDING.onContentItemLoadedCallback(self);
  }

  self.toggleSingleDate = function(checked)
  {
    var className = window.ONBOARDING.isSuperadmin == true ? "onboardingSuperadmin" : "onboarding";
    if (checked == true)
    {
      $(self.getSelector() + "-end-date").addClass(className + "--SubtopicDateInput-Is-Disabled");
      $(self.getSelector() + "-end-date-icon").addClass(className + "--SubtopicDateInput-Is-Disabled");
    }
    else
    {
      $(self.getSelector() + "-end-date").removeClass(className + "--SubtopicDateInput-Is-Disabled");
      $(self.getSelector() + "-end-date-icon").removeClass(className + "--SubtopicDateInput-Is-Disabled");
    }
  }

  self.showPDFFileDisplay = function(data)
  {
    $(self.getSelector() + "-dropzone").hide();
    $(self.getSelector() + "-file-display").show();
    $(self.getSelector() + "-file-display-link").attr("href", data.url);
    $(self.getSelector() + "-file-display-link-text").html(data.lastPDFFileName);
    $(self.getSelector()).data("has-file", true);
    self.subtopicData.validateParentTopic();
  }

  self.initDropzone = function()
  {
    // e.preventDefault();

    // var section = $(this).data('section');
    var newId = new Date().getTime();
    // var form = $(this).data('form').replace(/{{PDF_SUB}}/g, newId);
    // var appendedForm = $('#section-' + section + '-container .onboarding-section-pdfs-container').append(form);
    var dropArea = $(self.getSelector()).find(window.ONBOARDING.CSSClassName + '--SubtopicPDFDropzone');

    ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(function(eventName) {
      $(dropArea).on(eventName, function(e) {
        e.preventDefault()
        e.stopPropagation()
      });
    });

    ['dragenter', 'dragover'].forEach(function(eventName) {
      $(dropArea).on(eventName, function(e) {
        $(this).addClass('highlight');
      });
    });

    ['dragleave', 'drop'].forEach(function(eventName) {
      $(dropArea).on(eventName, function(e) {
        $(this).removeClass('highlight');
      });
    });

    $(dropArea).on('drop', function(e) {
      var dt = e.originalEvent.dataTransfer;
      var files = dt.files;
    
      self.subtopicData.handleFiles(files, $(this).data('pdf'), $(this).data('url'));
    });

    return false;
  }

  self.disableContentItem = function()
  {
    var disabledClass = "onboarding--Subtopic-Is-Disabled";
    switch(self.contentType)
    {
      case window.ONBOARDING.contentTypeEnum.link:
        $(self.getSelector()+"-label").addClass(disabledClass);
        $(self.getSelector()+"-url").addClass(disabledClass);
        break;
      case window.ONBOARDING.contentTypeEnum.date:
        $(self.getSelector() + "-single-date").addClass(disabledClass);
        $(self.getSelector()+"-label").addClass(disabledClass);
        $(self.getSelector()+"-start-date").addClass(disabledClass);
        $(self.getSelector()+"-end-date").addClass(disabledClass);
        $(self.getSelector()).find(".onboarding--SubtopicDateInputIconImage").addClass(disabledClass);
        $(self.getSelector() + "-single-date-control").removeClass("custom-switch-onboarding-cobalt");
        $(self.getSelector() + "-single-date-control").addClass("custom-switch-onboarding-cobalt-disabled");
        $(self.getSelector() + "-single-date").css("pointer-events", "none");
        $(self.getSelector() + "-single-date").prop("disabled", true);
        break;
      case window.ONBOARDING.contentTypeEnum.pdf:
        $(self.getSelector()).find(".onboarding--SubtopicPDFLabel").addClass(disabledClass);
        $(self.getSelector()).find(".onboarding--SubtopicPDFDropzone").addClass(disabledClass);
        $(self.getSelector()).find(".onboarding--SubtopicPDFDropzoneTargetIcon").addClass(disabledClass);
        $(self.getSelector()).find(".onboarding--SubtopicPDFInlineDelete").addClass(disabledClass);
        break;
    }
  }

  self.enableContentItem = function()
  {
    var disabledClass = "onboarding--Subtopic-Is-Disabled";
    switch(self.contentType)
    {
      case window.ONBOARDING.contentTypeEnum.link:
        $(self.getSelector()+"-label").removeClass(disabledClass);
        $(self.getSelector()+"-url").removeClass(disabledClass);
        break;
      case window.ONBOARDING.contentTypeEnum.date:
        $(self.getSelector() + "-single-date").removeClass(disabledClass);
        $(self.getSelector()+"-label").removeClass(disabledClass);
        $(self.getSelector()+"-start-date").removeClass(disabledClass);
        $(self.getSelector()+"-end-date").removeClass(disabledClass);
        $(self.getSelector()).find(".onboarding--SubtopicDateInputIconImage").removeClass(disabledClass);
        $(self.getSelector() + "-single-date-control").addClass("custom-switch-onboarding-cobalt");
        $(self.getSelector() + "-single-date-control").removeClass("custom-switch-onboarding-cobalt-disabled");
        $(self.getSelector() + "-single-date").prop("disabled", false);
        break;
      case window.ONBOARDING.contentTypeEnum.pdf:
        $(self.getSelector()).find(".onboarding--SubtopicPDFLabel").removeClass(disabledClass);
        $(self.getSelector()).find(".onboarding--SubtopicPDFDropzone").removeClass(disabledClass);
        $(self.getSelector()).find(".onboarding--SubtopicPDFDropzoneTargetIcon").removeClass(disabledClass);
        $(self.getSelector()).find(".onboarding--SubtopicPDFInlineDelete").removeClass(disabledClass);
        break;
    }
  }
}

window.ONBOARDING.findSubtopicData = function(subtopicID)
{
  //only should necessary for existing subtopic content that is loaded
  var topicValues = Object.values(window.ONBOARDING.topics);
  for (let i = 0; i < topicValues.length; i++)
  {
    var subtopicValues = Object.values(topicValues[i].subtopics);
    for (let j = 0; j < subtopicValues.length; j++)
    {
      if (subtopicValues[j].id == subtopicID)
      {
        return subtopicValues[j];
      }
    }
  }
}

window.ONBOARDING.registerToLoad = function(data, type)
{
  if (type == "subtopic")
  {
    if (!window.ONBOARDING.toLoad[type][data.topicID])
    {
      window.ONBOARDING.toLoad[type][data.topicID] = [];
    }

    window.ONBOARDING.toLoad[type][data.topicID].push(data);
  }
  else if (type == "contentItem")
  {
    if (!window.ONBOARDING.toLoad[type][data.subtopicID])
    {
      window.ONBOARDING.toLoad[type][data.subtopicID] = [];
    }

    window.ONBOARDING.toLoad[type][data.subtopicID].push(data);
  }
}

window.ONBOARDING.loadSubtopic = function(data)
{
  if (JSON.parse(data.isNew) != true)
  {
   window.ONBOARDING.topics[data.topicID].addSubtopic(null, data.id, data.order, false, data.isGlobal, data.isEditable, data.isCustom, data.isCompleted, data.isIncluded);
  }
 window.ONBOARDING.topics[data.topicID].loadSubtopic(data.id);
}

window.ONBOARDING.loadContentItem = function(data)
{
  var contentItem = new window.ONBOARDING.SubtopicContentItem(data.subtopicData, data.id, data.order, data.contentType, JSON.parse(data.isNew));
  contentItem.onLoad();

  data.subtopicData.addContentItem(contentItem);
}

window.ONBOARDING.handleLoadTopicUI = function(id, order, isNew)
{
  if (isNew == false)
  {
    window.ONBOARDING.addTopic(null, id, order, false);
  }
 window.ONBOARDING.topics[id].onLoad();
}

window.ONBOARDING.handleLoadSubtopicUI = function(topicID, id, order, isNew, isGlobal, isEditable, isCustom, isCompleted, isIncluded)
{
  var data = {topicID: topicID, id: id, order: order, isNew: isNew, isGlobal: isGlobal, isEditable: isEditable, isCustom: isCustom, isCompleted: isCompleted, isIncluded: isIncluded};
  if (window.ONBOARDING.topics[topicID])
  {
    window.ONBOARDING.loadSubtopic(data);
  }
  else
  {
    window.ONBOARDING.registerToLoad(data, "subtopic");
  }
}

window.ONBOARDING.handleLoadSubtopicContentUI = function(subtopicID, id, order, contentType, isNew = true)
{
  var data = {subtopicID: subtopicID, id: id, order: order, contentType: contentType, isNew: isNew}
  var subtopicData = window.ONBOARDING.findSubtopicData(subtopicID);

  if (subtopicData)
  {
    data.subtopicData = subtopicData;
    window.ONBOARDING.loadContentItem(data);
  }
  else
  {
    window.ONBOARDING.registerToLoad(data, "contentItem");
  }
}

window.ONBOARDING.handleFiles = function(files, pdf, url, subtopicID)
{
  var subtopic = window.ONBOARDING.findSubtopicData(subtopicID);
  if (subtopic)
  {
    subtopic.handleFiles(files, pdf, url);
  }
}

window.ONBOARDING.toggleEditMode = function(id, editMode)
{
  if (editMode == true)
  {
    $("#onboarding-topic-instructions" + id + "-show").hide();
    $("#onboarding-topic-header" + id + "-show").hide();
    $("#onboarding-topic-subtopics-list" + id + "-show").hide();

    $("#onboarding-topic-actions" + id + "-edit").show();
    $("#onboarding-topic-instructions" + id + "-edit").show();
    $("#onboarding-topic-header" + id + "-edit").show();
    $("#onboarding-topic-subtopics-list" + id + "-edit").show();
    window.ONBOARDING.toggleAddTopicButton(false);
  }
  else
  {
    $("#onboarding-topic-instructions" + id + "-show").show();
    $("#onboarding-topic-header" + id + "-show").show();
    $("#onboarding-topic-subtopics-list" + id + "-show").show();

    $("#onboarding-topic-actions" + id + "-edit").hide();
    $("#onboarding-topic-instructions" + id + "-edit").hide();
    $("#onboarding-topic-header" + id + "-edit").hide();
    $("#onboarding-topic-subtopics-list" + id + "-edit").hide();
    window.ONBOARDING.toggleAddTopicButton(true);
  }
}

window.ONBOARDING.toggleAddTopicButton = function(enabled)
{
  if (enabled == true)
  {
    $("#onboarding-add-topic").removeClass("onboardingBlueprint--AddTopicButton-Is-Disabled");
  }
  else
  {
    $("#onboarding-add-topic").addClass("onboardingBlueprint--AddTopicButton-Is-Disabled");
  }
}

//topic
window.ONBOARDING.addTopic = function(e, id, order, isNew)
{
  var topic = new window.ONBOARDING.Topic(id, order, isNew);
 window.ONBOARDING.topics[topic.id] = topic;

  if (isNew == true)
  {
    window.ONBOARDING.appendNewTopicForm(topic);
  }
}

window.ONBOARDING.appendNewTopicForm = function(topicData)
{
  $("#onboarding-topics-list").append(window.ONBOARDING.getNewTopicPartial(topicData));
}

window.ONBOARDING.showDeleteModal = function(id, entityName, callback)
{
  $("#onboarding-delete-modal-confirm").unbind("click");
  $(".onboarding-delete-modal-entity-name-handler").html(entityName)
  $('#onboarding-delete-modal').modal('show');
  $("#onboarding-delete-modal-confirm").click(function(){
    callback(id);
  })
}

window.ONBOARDING.deleteTopic = function(id)
{
  var isNew = window.ONBOARDING.topics[id].isNew;
  $("#onboarding-topic-" + id).remove();
  delete window.ONBOARDING.topics[id];
  //make ajax request to delete path and send id

  if (isNew == false)
  {
    $.ajax({
      url: '<%= superadmin_hub_tools_onboarding_onboarding_topics_path(@hub) %>',
      data: {topic_id: id},
      method: "DELETE"
    })
  }
  else
  {
    // window.location.replace("<%= superadmin_hub_tools_onboarding_onboarding_blueprints_path(@hub) %>");
  }
}

window.ONBOARDING.hideSubtopic = function(subtopic)
{
  $(subtopic.getSelector()).hide();
}

window.ONBOARDING.showSubtopic = function(subtopic)
{
  $(subtopic.getSelector()).show();
}

window.ONBOARDING.deleteSubtopic = function(subtopic)
{
  var isNew = subtopic.isNew;
  $(subtopic.getSelector()).remove();
  delete window.ONBOARDING.topics[subtopic.topicID].subtopics[subtopic.id];
  // if (isNew == false)
  // {
  //   $.ajax({
  //     url: '<%= superadmin_hub_tools_onboarding_onboarding_subtopics_path(@hub) %>',
  //     data: {subtopic_id: subtopic.id},
  //     method: "DELETE"
  //   })
  // }
}

window.ONBOARDING.hideContentItem = function(item)
{
  $(item.getSelector()).data("deleted", true);
  $(item.getSelector()).hide();
}

window.ONBOARDING.showContentItem = function(item)
{
  $(item.getSelector()).show();
}

window.ONBOARDING.deleteContentItem = function(item)
{
  var isNew = item.isNew;
  $(item.getSelector()).remove();

  if (isNew == false)
  {
    var url = '<% destroy_link_superadmin_hub_tools_onboarding_onboarding_subtopics_path(@hub) %>';
    
    switch(item.contentType)
    {
      case window.ONBOARDING.contentTypeEnum.link:
        url = '<%= destroy_link_superadmin_hub_tools_onboarding_onboarding_subtopics_path(@hub) %>';
        break;
      case window.ONBOARDING.contentTypeEnum.pdf:
        url = '<%= destroy_pdf_superadmin_hub_tools_onboarding_onboarding_subtopics_path(@hub) %>';
        break;
      case window.ONBOARDING.contentTypeEnum.date:
        url = '<%= destroy_date_superadmin_hub_tools_onboarding_onboarding_subtopics_path(@hub) %>';
        break;
    }

    // $.ajax({
    //   url: url,
    //   data: {item_id: item.id},
    //   method: "DELETE"
    // })
  }
  else
  {
    for (let i = 0; i < item.subtopicData.files.length; ++i)
    {
      if (item.subtopicData.files[i].item_id == item.id)
      {
        item.subtopicData.files.splice(i, 1);
        break;
      }
    }
  }
}

window.ONBOARDING.cancelTopicChanges = function(id)
{
  var selector = "#onboarding-topic-" + id;
  $(selector).trigger('reset');

  $(selector).find('*[data-is-new="true"]').each(function(){
    $(this).remove();
  });
  window.ONBOARDING.toggleAddTopicButton(true);
}

window.ONBOARDING.getNewTopicPartial = function(topicData)
{
  var renderedFormString = "<%= escape_javascript(render partial: 'superadmin/hubs/tools/onboarding/topics/new', locals: {id: '_ID_', order: '_#_' }) %>";
  renderedFormString = renderedFormString.replaceAll("_ID_", topicData.id);
  renderedFormString = renderedFormString.replaceAll("_#_", topicData.order);
  return renderedFormString;
}

window.ONBOARDING.getNewSubtopicPartial = function(subtopicData)
{
  var renderedFormString = "<%= escape_javascript(render partial: 'superadmin/hubs/tools/onboarding/subtopics/form', locals: {id: '_ID_', order: '_#_', topic_id: '_TOPICID_', is_new: true }) %>";
  renderedFormString = renderedFormString.replaceAll("_ID_", subtopicData.id);
  renderedFormString = renderedFormString.replaceAll("_TOPICID_", subtopicData.topicID);
  renderedFormString = renderedFormString.replaceAll("_#_", subtopicData.order);
  return renderedFormString;
}

window.ONBOARDING.nextTopicNumber = function()
{
  return Object.keys(topics).length + 1;
}

window.ONBOARDING.toggleSubmitClass = function(id, enabled = true)
{
  var submitSelector = "#onboarding-topic-submit-" + id;
  if (enabled == true)
  {
      $(submitSelector).removeClass("onboarding--TopicButton-Is-Disabled");
  }
  else
  {
      $(submitSelector).addClass("onboarding--TopicButton-Is-Disabled");
  }
}

window.ONBOARDING.setPartialRenderString = function(key, value)
{
  window.ONBOARDING.partialRenderStrings[key] = value;
}