$(document).on('turbolinks:load', function(){
  window.initParsleyForm('#edit_user');
  window.initParsleyForm('#new_user');
  
  if($('#user_current_password').data('error')) {
    $('#user_current_password').parsley().addError('incorrect_password', { message: 'Incorrect password' });
    $('#user_current_password').on('change', function() {
      $('#user_current_password').on('change', function() {
        $('#user_current_password').parsley().removeError('incorrect_password');
      });
    });
  }
});
