$(document).on('turbolinks:load', function(){
  var parsleyForm = $('form.js-hub-form').parsley({
    errorClass: 'parsley-error',
    successClass: 'parsley-success',
    errorsWrapper: '<div class="invalid-feedback d-block"></div>',
    errorTemplate: '<span></span>'
  });

  $(document).off('click', '.js-toggle-assessment');
  $(document).on('click', '.js-toggle-assessment', function(e) {
    var url = $(this).data('url');
    var enabled = $(this).val();

    $.ajax({
      method: 'POST',
      url: url,
      dataType: 'json',
      contentType: 'application/json',
      data: JSON.stringify({ authenticity_token: $('[name="csrf-token"]')[0].content, enabled: enabled })
    });
  });

  $(document).off('click', '.js-toggle-additional-questions');
  $(document).on('click', '.js-toggle-additional-questions', function(e) {
    var url = $(this).data('url');
    var enabled = $(this).val();

    $.ajax({
      method: 'POST',
      url: url,
      dataType: 'json',
      contentType: 'application/json',
      data: JSON.stringify({ authenticity_token: $('[name="csrf-token"]')[0].content, additional_settings: { additional_questions: enabled } })
    });
  });

  $('.js-file-upload').on('change', function(e) {
    var file = $(this).prop('files')[0];
    var wrapper = $(this).data('wrapper');
    if( file != undefined ) {
      switch (file.type) {
        case 'image/jpeg':
        case 'image/png':
        case 'image/svg+xml':
          $(wrapper + ' .image-preview img').prop('src', URL.createObjectURL(file));
          $(wrapper).addClass('file-added');
          $('.file-tooltip').removeClass('error');
          break;
        default:
          $(this).val('');
          $('.file-tooltip').addClass('error');
      }
    } else {
      $(wrapper + ' .image-preview img').prop('src', '');
      $(wrapper).removeClass('file-added');
    }
  });

  $('.js-remove-image').on('click', function(e) {
    e.preventDefault();

    var wrapper = $(this).data('wrapper');
    $(wrapper + ' .js-file-upload').val('').change();

    return false;
  })
});
