$(document).on('turbolinks:load', function(){
  window.initParsleyForm('#edit_superadmin');
  
  if($('#superadmin_current_password').data('error')) {
    $('#superadmin_current_password').parsley().addError('incorrect_password', { message: 'Incorrect password' });
    $('#superadmin_current_password').on('change', function() {
      $('#superadmin_current_password').on('change', function() {
        $('#superadmin_current_password').parsley().removeError('incorrect_password');
      });
    });
  }
});
