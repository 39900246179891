$(document).on('turbolinks:load', function(){
  var checkStartButton = function() {
    if( ($('.js-bpa-committee-cb:checked').length > 0) || ($('.js-bpa-committee-none-cb:checked').length > 0)) {
      $('#start-assessment').prop('disabled', false);
    } else {
      $('#start-assessment').prop('disabled', true);
    }
  }

  $(document).off('change', '.js-bpa-committee-cb');
  $(document).on('change', '.js-bpa-committee-cb', function(e){
    if( $(this).prop('checked') ) {
      $('.js-bpa-committee-none-cb').prop('checked', false);
    }
    checkStartButton();
  });

  $(document).off('change', '.js-bpa-committee-none-cb');
  $(document).on('change', '.js-bpa-committee-none-cb', function(e){
    if( $(this).prop('checked') ) {
      $('.js-bpa-committee-cb').prop('checked', false);
    }
    checkStartButton();
  });

  $(document).off('change', '.js-bpa-section-form');
  $(document).on('change', '.js-bpa-section-form', function(e){
    var complete = true;
    $(this).find('.bpa-participate__question-row').each(function(i, el) {
      if( $(el).hasClass('js-comments-row') ) return true;

      if( $(el).hasClass('js-text-response-row') && $(el).find('textarea').val() === '') {
        complete = false;

        return false;
      } else if( !$(el).hasClass('js-text-response-row') && $(el).find('[type=radio]:checked').length == 0) {
        complete = false;

        return false;
      }
    });

    if(complete) {
      $(this).find('.js-bpa-next-btn').prop('disabled', false);
    } else {
      $(this).find('.js-bpa-next-btn').prop('disabled', true);
    }

    Rails.fire(this, 'submit');
  });
});
