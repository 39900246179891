// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import JQuery from 'jquery';

window.Rails = Rails;
window.$ = window.JQuery = window.jQuery = JQuery;

require('jquery-ui-dist/jquery-ui');
require("bootstrap-datepicker");

import * as Popper from "@popperjs/core"
window.Popper = Popper;

import * as Bootstrap from "bootstrap"
window.bootstrap = Bootstrap;

require("lib/parsley");
Parsley.addMessages('en', {
  required: 'Required Field',
  type: {
    email: 'Invalid Email',
  }
});

require("superadmin/hubs");
require("superadmin/assessments");
require("superadmin/profile");
require("superadmin/fields");
require("hubs/sidebar");
require("hubs/profile");
require("hubs/boards/editing");
require("hubs/boards/index");
require("hubs/assessments/results");
require("assessments/hub_manage");
require("assessments/board_manage");
require("assessments/bpa/participate");
require("assessments/ceo/participate");
require("tools/onboarding.js.erb");

Rails.start()
Turbolinks.start()
ActiveStorage.start()


window.initParsleyForm = function(formIdentifier) {
  var parsleyForm = $(formIdentifier).parsley({
    errorClass: 'parsley-error',
    successClass: 'parsley-success',
    errorsWrapper: '<div class="invalid-feedback d-block"></div>',
    errorTemplate: '<span></span>'
  });

  $(formIdentifier + ' .form-control').on('change', function() {
    $(this).parents('.form-group').removeClass('hidden-error');
  });

  $(formIdentifier + ' [type=submit]').on('click', function() {
    $(formIdentifier + ' .form-group').removeClass('hidden-error');
  });

  if(parsleyForm != undefined) {
    window.initCustomSelectValidation(formIdentifier, parsleyForm);
  }
};

window.initCustomSelectValidation = function(formIdentifier, parsleyForm) {
  if($(formIdentifier + ' .select-box').length > 0) {
    $(formIdentifier + ' .select-box').each(function(_i, el) {
      var selectBox = $(el);
      var errorMsg = selectBox.data('error');
      if(errorMsg == '') errorMsg = 'Required Field';
  
      selectBox.on('change', function() {
        selectBox.parents('.form-group').removeClass('hidden-error');
        parsleyForm.validate();
        $(formIdentifier + ' label').removeClass('selected');
        if($(this).prop('checked')) {
          $("label[for=" + $(this).prop('id') + "]").addClass('selected');
        }
      });
  
      parsleyForm.on('form:validate', function(formInstance) {
        if(selectBox.find('.select-box__input[value!=""]:checked').length == 0) {
          selectBox.find('.select-box__input:checked').parsley().removeError('requiredSelect', { updateClass: true });
          selectBox.find('.select-box__input:checked').parsley().addError('requiredSelect', { message: errorMsg, updateClass: true });
          selectBox.find('.select-box__input:checked').addClass('error');
          formInstance.validationResult = false;
          if(formInstance.submitEvent != undefined) formInstance.submitEvent.preventDefault();
        } else {
          selectBox.find('.select-box__input:checked').parsley().removeError('requiredSelect', { updateClass: true });
          selectBox.find('.select-box__input').removeClass('error');
        }
      });
    });
  }
};

var updateSidebar = function() {
  if( $('#sidebar-wrapper').hasClass('js-hidden-sb') || $('#sidebar-wrapper').css('visibility') == 'hidden' ) return;
  if( $(window).width() < 1000 ) {
    $('#sidebar-wrapper').removeClass('show');
  } else {
    $('#sidebar-wrapper').addClass('show');
  };
}

$(document).on('turbolinks:load', updateSidebar);

$(window).on('resize', updateSidebar);

function millisToMinutesAndSeconds(millis) {
  var minutes = Math.floor(millis / 60000);
  var seconds = ((millis % 60000) / 1000).toFixed(0);
  return (
    seconds == 60 ?
    (minutes+1) + ":00" :
    minutes + ":" + (seconds < 10 ? "0" : "") + seconds
  );
}

var resetLoginTimeout = function(event, force = false) {
  if($(event.target).data('skipLoginRefresh') == 'true') {
    event.stopPropagation();
    return false;
  }

  var timeout = $('body').data('timeout');
  if( (timeout > 0) && (force || !window.logoutModal._isShown)) {
    window.loginTimeout = timeout;
    checkTimeout();
    $.ajax('/login_refresh', { method: 'HEAD' }).done(function() {
      $.ajax('/login_check').fail(function() {
        var login = $('body').data('login');
        window.location = login;
      }).done(function( data, _textStatus, _jqXHR ) {
        window.loginTimeout = data.timeout;
      });
    });
  }
}

var checkTimeout = function() {
  if(window.loginTimeout <= 0) {
    $('#logout-timer').text(millisToMinutesAndSeconds(0));
    window.timerWorker.postMessage({ 'run_flag': false });
    window.location = window.location;
  } else if(window.loginTimeout <= window.logoutTimer) {
    $('#logout-timer').text(millisToMinutesAndSeconds(window.loginTimeout));
    window.logoutModal.show();
  } else {
    window.logoutModal.hide();
  }
}

var startLoginTimeout = function() {
  if($('#timeout-modal')[0] != undefined) {
    window.logoutModal = new bootstrap.Modal($('#timeout-modal')[0]);
    $('#logout-reset').off('click');
    $('#logout-reset').on('click', function() {
      resetLoginTimeout({}, true);
    });
  }

  var timeout = $('body').data('timeout');
  if( (timeout > 0) && (window.logoutTimer == undefined) ) {
    window.logoutTimer = timeout / 2;
    $.ajax('/login_check').done(function( data, _textStatus, _jqXHR ) {
      window.loginTimeout = data.timeout;

      window.timerWorker.postMessage({ 'run_flag': false });
      window.timerWorker.postMessage({ 'set_timeout': 500 });
      window.timerWorker.postMessage({ 'run_flag': true });
    });

    $(document).on('keydown click turbolinks:load', resetLoginTimeout);
  }
}

window.timerWorker = new Worker('/timer_worker.js');
window.timerWorker.onmessage = function() {
  window.loginTimeout = window.loginTimeout - 500;
  var checkTime = (window.loginTimeout % 10000) == 0;
  var timeoutNotReady = window.loginTimeout > 1000
  if(window.logoutModal._isShown && checkTime && timeoutNotReady) {
    $.ajax('/login_check').fail(function() {
      var login = $('body').data('login');
      window.location = login;
    }).done(function( data, _textStatus, _jqXHR ) {
      window.loginTimeout = data.timeout;
      checkTimeout();
    });
  } else {
    checkTimeout();
  }
}

$(document).on('turbolinks:load', startLoginTimeout);

$(document).on('turbolinks:load', function() {
  $('[data-bs-toggle="tooltip"]').each(function(_i, tooltipTriggerEl) {
    return new window.bootstrap.Tooltip(tooltipTriggerEl);
  })

  window.setTimeout(function() {
    $(".alert-dismissible").fadeTo(500, 0).slideUp(500, function(){
      $(this).remove();
    });
  }, 10000);

  window.setTimeout(function() {
    $(".timeout-notification").fadeTo(500, 0).slideUp(500, function(){
      $(this).remove();
    });

    $(".password-notification").fadeTo(500, 0).slideUp(500, function(){
      $(this).remove();
    });
  }, 30000);

  $('[data-bs-toggle="tooltip"]').each(function(_i, el) {
    return new bootstrap.Tooltip(el);
  });
  
  $(document).off('focus', '.select-box__current');
  $(document).on('focus', '.select-box__current', function(e) {
    var wrapper = $(this);
    wrapper.find('.select-box__icon').off('click');

    setTimeout(function() {
      wrapper.find('.select-box__icon').on('click', function(e) {
        wrapper.blur();
      });
    }, 500);
  });

  $(document).off('blur', '.select-box__current');
  $(document).on('blur', '.select-box__current', function(e) {
    var wrapper = $(this);
    wrapper.find('.select-box__icon').off('click');
  });
});

(function($bs) {
  const CLASS_NAME = 'has-child-dropdown-show';
  $bs.Dropdown.prototype.toggle = function(_orginal) {
    return function() {
      document.querySelectorAll('.' + CLASS_NAME).forEach(function(e) {
        e.classList.remove(CLASS_NAME);
      });
      let dd = this._element.closest('.dropdown').parentNode.closest('.dropdown');
      for (; dd && dd !== document; dd = dd.parentNode.closest('.dropdown')) {
        dd.classList.add(CLASS_NAME);
      }
      return _orginal.call(this);
    }
  }($bs.Dropdown.prototype.toggle);

  $(document).on('turbolinks:load', function(){
    document.querySelectorAll('.dropdown').forEach(function(dd) {
      dd.addEventListener('hide.bs.dropdown', function(e) {
        if (this.classList.contains(CLASS_NAME)) {
          this.classList.remove(CLASS_NAME);
          e.preventDefault();
        }
        e.stopPropagation(); // do not need pop in multi level mode
      });
    });

    // for hover
    document.querySelectorAll('.dropdown-hover, .dropdown-hover-all .dropdown').forEach(function(dd) {
      dd.addEventListener('mouseenter', function(e) {
        let toggle = e.target.querySelector(':scope>[data-bs-toggle="dropdown"]');
        if (!toggle.classList.contains('show')) {
          $bs.Dropdown.getOrCreateInstance(toggle).toggle();
          dd.classList.add(CLASS_NAME);
          $bs.Dropdown.clearMenus();
        }
      });
      dd.addEventListener('mouseleave', function(e) {
        let toggle = e.target.querySelector(':scope>[data-bs-toggle="dropdown"]');
        if (toggle.classList.contains('show')) {
          $bs.Dropdown.getOrCreateInstance(toggle).toggle();
        }
      });
    });
  });
})(window.bootstrap);
