$(document).on('turbolinks:load', function(){
  var filterActivity = function(){
    $('.js-activity-filter').removeClass('d-none');

    var boardNameFilter = $('#activity-filter-boards').val();
    if(boardNameFilter != '') {
      $('.js-activity-filter').each(function(_i, el) {
        var boardName = $(el).data('name');
        if( !boardName.includes( boardNameFilter.toLowerCase() ) ){
          $(el).addClass('d-none');
        }
      });
    }

    var boardDateFilter = $('#filter-dates').val();
    if(boardDateFilter != '') {
      $('.js-activity-filter').each(function(_i, el) {
        var boardDate = $(el).data('date').toString();
        if(!boardDate.includes(boardDateFilter)){
          $(el).addClass('d-none');
        }
      });
    }

    if($('#not-launched .js-activity-filter:not(.d-none)').length > 0) {
      $('#not-launched').addClass('show');
      $('.activity-track__dropdown.not-launched').attr('aria-expanded', true);
    }

    if($('#in-process .js-activity-filter:not(.d-none)').length > 0) {
      $('#in-process').addClass('show');
      $('.activity-track__dropdown.in-process').attr('aria-expanded', true);
    }

    if($('#completed .js-activity-filter:not(.d-none)').length > 0) {
      $('#completed').addClass('show');
      $('.activity-track__dropdown.completed').attr('aria-expanded', true);
    }
  }

  $(document).off('keyup', '#search-boards');  
  $(document).on('keyup', '#search-boards', function(e) {
    var search = $(this).val().toLowerCase();
    if(search == '') {
      $('.js-board-index-link').removeClass('d-none');
    } else {
      $('.js-board-index-link').each(function(_i, el) {
        var boardName = $(el).data('boardName');
        if(boardName.includes(search)){
          $(el).removeClass('d-none');
        } else {
          $(el).addClass('d-none');
        }
      });
    }
  });

  $(document).off('keyup', '#activity-filter-boards');
  $(document).on('keyup', '#activity-filter-boards', filterActivity);

  $(document).off('change', '#filter-dates');
  $(document).on('change', '#filter-dates', filterActivity);
});
