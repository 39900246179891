$(document).on('turbolinks:load', function(){
  $(document).off('click', '.js-filter-option');
  $(document).on('click', '.js-filter-option', function(e) {
    e.preventDefault();

    if($(this).data('filter') == 'field') {
      $('[name=filter_field]').val($(this).data('field'));
      $('[name=filter_value]').val($(this).data('value'));
    } else {
      $('[name=filter_field]').val('');
      $('[name=filter_value]').val('');
    }

    $('#current-filter').text($(this).data('title'));
    $('#current-filter').prop('title', $(this).data('title'));
    Rails.fire($('#results-form')[0], 'submit');

    return false;
  });

  $(document).off('click', '.js-group-option');
  $(document).on('click', '.js-group-option', function(e) {
    e.preventDefault();

    if($(this).data('compare') == 'field') {
      $('[name=group_field]').val($(this).data('field'));
    } else {
      $('[name=group_field]').val('');
    }

    $('#current-group').text($(this).data('title'));
    $('#current-group').prop('title', $(this).data('title'));
    Rails.fire($('#results-form')[0], 'submit');

    return false;
  });
});
